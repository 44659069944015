html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #1B2422;
}

div {
    box-sizing: border-box;
}

.MuiTooltip-popper {
    display: none
}